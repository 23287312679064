import React, { ReactElement, useState } from 'react'
import { DateTime } from 'luxon'

import Layout from 'components/Layout'
import Preloader from 'components/Preloader'
import NotFound from 'components/NotFound'
import { useAuthentication, useUserLoading } from 'hooks'
import { BodyComp } from 'types/bodyComp'

import pdfIcon from 'assets/pdf-icon.svg'

import './Profile.scss'

interface BodyCompItem {
  createdAt: string
}

function orderBodyCompItemsLatestFirst(a: BodyCompItem, b: BodyCompItem) {
  const dateA = DateTime.fromISO(a.createdAt).toMillis()
  const dateB = DateTime.fromISO(b.createdAt).toMillis()

  return dateB - dateA
}

export default function Profile(): ReactElement {
  const { data, isLoaded } = useUserLoading()
  const { isAuthenticated, logout } = useAuthentication()

  const [fullscreenScan, setFullscreenScan] = useState<string>()

  let firstName: string | undefined,
    lastName: string | undefined,
    birthDate: string | undefined,
    email: string | undefined,
    phoneNumber: string | undefined,
    photo: string | undefined,
    bodyComp: BodyComp | undefined

  if (data) {
    firstName = data.firstName
    lastName = data.lastName
    birthDate = data.birthDate
    email = data.email
    phoneNumber = data.phoneNumber
    photo = data.photo
    bodyComp = data.bodyComp
  }

  if (isAuthenticated) {
    return isLoaded ? (
      <Layout className="profile-page">
        <div className="profile__header">
          <div className="profile__user">
            {photo && (
              <img
                className="profile__user__avatar"
                src={photo}
                alt={`${firstName} ${lastName}`}
              />
            )}
            <h1 className="profile__user__name">
              {firstName} {lastName}
            </h1>
          </div>
          <button
            className="profile__logout btn btn-secondary"
            onClick={logout}
          >
            Logout
          </button>
        </div>

        <table className="profile__data">
          <tbody>
            <tr>
              <td className="profile__data__name">Birth Date:</td>
              <td className="profile__data__value">{birthDate}</td>
            </tr>
            <tr>
              <td className="profile__data__name">Email:</td>
              <td className="profile__data__value">{email}</td>
            </tr>
            <tr>
              <td className="profile__data__name">Phone:</td>
              <td className="profile__data__value">{phoneNumber}</td>
            </tr>
          </tbody>
        </table>

        {bodyComp && (
          <>
            {bodyComp.reports && (
              <section className="profile__bodycomp">
                <h4>BodyComp Reports</h4>

                <div className="row">
                  {bodyComp.reports
                    .sort(orderBodyCompItemsLatestFirst)
                    .map(({ url, createdAt, uploadedAt }) => {
                      const dateCreated = DateTime.fromISO(createdAt)
                        .setLocale('en')
                        .toFormat('DDD')
                      const dateUploaded = DateTime.fromISO(uploadedAt)
                        .setLocale('en')
                        .toFormat('DDD')

                      return (
                        <div className="col-md-4" key={url}>
                          <article className="profile__bodycomp__item profile__bodycomp__item--report">
                            <a
                              href={url}
                              className="profile__bodycomp__item__icon"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={pdfIcon} alt="PDF Icon" />
                            </a>
                            <div>
                              <h5 className="profile__bodycomp__item__title">
                                <a
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {dateCreated}
                                </a>
                              </h5>
                              <p className="profile__bodycomp__item__date">
                                Uploaded on {dateUploaded}
                              </p>
                            </div>
                          </article>
                        </div>
                      )
                    })}
                </div>
              </section>
            )}

            {bodyComp.scans && (
              <section className="profile__bodycomp">
                <h4>BodyComp Scans</h4>

                <div className="row">
                  {bodyComp.scans
                    .sort(orderBodyCompItemsLatestFirst)
                    .reverse()
                    .map(({ url, createdAt }) => {
                      const dateCreated = DateTime.fromISO(createdAt)
                        .setLocale('en')
                        .toFormat('DDD')

                      return (
                        <div
                          className="col-6 col-sm-4 col-md-3 col-lg-2"
                          key={url}
                        >
                          <article className="profile__bodycomp__item">
                            <img
                              className="profile__bodycomp__item__image"
                              src={url}
                              alt={`Scan from ${dateCreated}`}
                              onClick={() => setFullscreenScan(url)}
                            />
                            <p className="profile__bodycomp__item__date">
                              {dateCreated}
                            </p>
                          </article>
                        </div>
                      )
                    })}
                </div>
              </section>
            )}
          </>
        )}

        {fullscreenScan && (
          <img
            className="fullscreen-image"
            src={fullscreenScan}
            onClick={() => setFullscreenScan(undefined)}
          />
        )}
      </Layout>
    ) : (
      <Preloader />
    )
  } else
    return (
      <Layout>
        <NotFound />
      </Layout>
    )
}
