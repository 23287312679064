import React from 'react'
import { RouteComponentProps } from 'types/reach-router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotFound = (props: RouteComponentProps) => {
  return (
    <>
      <h1>Oops!</h1>
      <p>The page you&apos;re looking for is not here.</p>
    </>
  )
}

export default NotFound
